import { Row, Col, Button, TextInput, DatePickerV2, Loader, Alert, Checkbox, CheckboxGroup, } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useEffect, useState } from 'react';
import useAuthentication from 'src/hooks/useAuthentication';
import useTranslate from 'src/hooks/useTranslate';
import { PersonalInformationText } from 'src/packages/cli/constants/PersonalInformationText';
import { validateDateIsOnOrBeforeToday } from 'src/packages/cli/utils/validationUtils';
import { dateMask } from 'src/packages/eoi/utils/InputMasks';
import { globalPhoneNumberFormat, numbersOnly, phoneNumberValidator, validateLastFourSSN, } from 'src/utils/validationUtils';
import ProveText from './ProveText';
import useProveData from './useProveData';
import { verifyIdentity } from 'src/api/AuthServiceApi';
import { parsePhoneNumber } from 'libphonenumber-js';
import { format } from 'date-fns';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import Cookies from 'js-cookie';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const VerifyForm = ({ altDisplay = false }) => {
    const { proveFieldsRequired, setVerificationData, setTrustScore } = useProveData();
    const { user, authHeader } = useAuthentication();
    const { t } = useTranslate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [failCount, setFailCount] = useState(0);
    const [formApi, setFormApi] = useState();
    const { trackEvent, trackView } = useTealium();
    const pageL4 = CustomEmployeeText('employee view'); //roundout is EE only right now
    /* Initialize fail count from cookie */
    useEffect(() => {
        // Initialize failures on cookie or user token - cookie more up to date (user token not refreshed on prove fail)
        let failCount = 0;
        let cookieFailures = Cookies.get('verifyFailCount');
        if (cookieFailures) {
            failCount = parseInt(cookieFailures);
        }
        if (failCount) {
            setFailCount(failCount);
            if (failCount >= 2) {
                setError(true);
            }
        }
        //TODO: look into profile page multiple re-renders, 
        if (!user?.isIdentityVerified) {
            if (!loading && proveFieldsRequired) {
                if (!window.location.pathname.includes('/accountAccess')) {
                    trackView({
                        page_l3: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                        page_l4: pageL4
                    });
                }
            }
        }
    }, [proveFieldsRequired, user]);
    const submit = async (values) => {
        try {
            setLoading(true);
            setError(false);
            const formattedPhoneNumber = values.phoneNumber.replace(/[^0-9+]/g, '');
            const phoneNumber = parsePhoneNumber(formattedPhoneNumber, 'US');
            const verifyData = {
                dob: values?.dateOfBirth ? format(new Date(values?.dateOfBirth), 'yyyy-MM-dd') : undefined,
                last4ssn: values?.lastFourSsn,
                phoneNumber: {
                    number: phoneNumber?.nationalNumber.toString(),
                    countryCode: phoneNumber?.countryCallingCode.toString(),
                },
                cookieId: user?.webGUID, // does not really matter but should be consistent between calls within same session
            };
            // console.log('data', verifyData);
            const verifyResponse = await verifyIdentity(verifyData, authHeader());
            const trustScore = verifyResponse.data;
            // console.log('trustScore', trustScore);
            if (!trustScore.valid) {
                throw new Error('trust score invalid');
                // possibly may need to put failcount here instead of in error catch
                // thinking that 500 errors shouldn't cause failcount to go up
            }
            setVerificationData(verifyData);
            setTrustScore(trustScore);
            setLoading(false);
            setError(false);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'continue',
                event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                event_version: 'account lookup',
                event_status: 'success',
            });
        }
        catch (error) {
            setLoading(false);
            setError(true);
            setFailCount(failCount + 1);
            Cookies.set('verifyFailCount', `${failCount + 1}`, { expires: 1 }); //24 hour cookie
            formApi?.setValue('lastFourSsn', undefined);
            formApi?.setValue('phoneNumber', undefined);
            formApi?.setValue('assertionCheck', undefined);
            if (failCount === 0) {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: t(ProveText.verifyError1),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'account lookup',
                    event_results: `prove failure - attempt ${failCount + 1}`,
                });
            }
            else {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: t(ProveText.verifyError2),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOUNT_ACCESS,
                    event_version: 'phone lookup',
                    event_results: 'prove attempts exceeded',
                });
            }
        }
    };
    return (<>
      {proveFieldsRequired?.identityVerified ? (<Alert type='success'>{t(ProveText.proveCodePreviousSuccessAlert)}</Alert>) : (<Loader data-testid='verify-form-loader' isShown={loading || !proveFieldsRequired}>
          {error && failCount < 2 && <Alert type='error'>{t(ProveText.verifyError1)}</Alert>}
          {error && failCount >= 2 && <Alert type='error'>{t(ProveText.verifyError2)}</Alert>}
          {failCount < 2 && (<>
              <p>{t(ProveText.verifySub1)}</p>
              <p>{t(ProveText.verifySub2)}</p>
              <Form style={{ maxWidth: '33rem' }} data-testid='verify-form' getApi={setFormApi} onSubmit={submit}>
                {proveFieldsRequired?.dob && (<Row>
                    <Col>
                      <DatePickerV2 data-testid='dateOfBirth' field={'dateOfBirth'} label={t(PersonalInformationText.dateOfBirthEE)} ariaLabelText={t(PersonalInformationText.dateOfBirthEE)} validate={(props) => validateDateIsOnOrBeforeToday(props, t, true)} mask={dateMask} validateOnBlur={true}/>
                    </Col>
                  </Row>)}
                {proveFieldsRequired?.ssn && (<Row>
                    <Col>
                      <TextInput data-testid='lastFourSsn' field={'lastFourSsn'} label={t(ProveText.last4SsnLabel)} validate={(value) => validateLastFourSSN(value, t)} maxLength={4} mask={numbersOnly} ariaLabelText={t(ProveText.last4SsnLabel)} validateOnBlur={true} iconColor={'secondaryDark'} type={'password'} toggleShowPassword={true}/>
                    </Col>
                  </Row>)}
                {proveFieldsRequired?.phone && (<Row>
                    <Col>
                      <TextInput data-testid={'phoneNumber'} field={'phoneNumber'} label={'Personal phone number'} mask={globalPhoneNumberFormat} placeholder={'555-555-5555'} type={'text'} validate={phoneNumberValidator} validateOnBlur={true}/>
                    </Col>
                  </Row>)}
                <Row>
                  <Col>
                    <CheckboxGroup field={'checkboxInputs'} label={''} hideLabel={true} validate={fieldRequired} validateOnChange={true}>
                      <Checkbox data-testid={'assertionCheck'} field={'assertionCheck'} label={t(ProveText.attestationLabel)}/>
                    </CheckboxGroup>
                  </Col>
                </Row>
                {proveFieldsRequired && (<Row>
                    <Col>
                      <Button data-testid='submit' style={{ width: '100%' }} type='submit' buttonType='primary' disabled={loading}>
                        {t(ProveText.submitLabel)}
                      </Button>
                    </Col>
                  </Row>)}
              </Form>
            </>)}
        </Loader>)}
    </>);
};
export default VerifyForm;
