import React from 'react';
import { Col, Row, TextInput } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { validateTextArea } from 'src/packages/cli/utils/validationUtils';
import { useFormState } from 'informed';
const OtherSurgery = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const otherInitialValue = absenceData?.injuryOther;
    const illnessFieldAlignment = values.absenceData?.claimantCondition === 'S' && (values.absenceData?.surgeryIndicator === 'N' || values.absenceData?.surgeryIndicator === 'U' || values.absenceData?.surgeryIndicator === 'Y' && values.absenceData.injuryTier === 'Other');
    const injuryFieldAlignment = values.absenceData?.claimantCondition === 'I' && (values.absenceData?.surgeryIndicator === 'N' || values.absenceData?.surgeryIndicator === 'U' || values.absenceData?.surgeryIndicator === 'Y' && values.absenceData.injuryTier === 'Other');
    return (<>
            <Row className='mb-3' data-private={true}>
                <Col data-testid='otherSurgeryCol' offset={`${illnessFieldAlignment && !injuryFieldAlignment ? 'md-6' : ''}`} md={6}>
                    <TextInput field={'injuryOther'} key={'injuryOther'} data-testid='otherSurgery' label={t(formLabels.otherIllnessInjurySurgery)} 
    //@ts-ignore FIXME: type error
    ariaLabeltext={t(formLabels.otherIllnessInjurySurgery)} validateOnBlur={true} validateOnChange={true} data-private={true} validate={(props) => validateTextArea({ value: props, t, charLimit: 50 })} initialValue={otherInitialValue}/>
                </Col>
            </Row>
        </>);
};
export default OtherSurgery;
