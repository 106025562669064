import React from 'react';
import { Alert, Button, Col, Heading, Row } from '@digitools/honest-react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import useDentalDataContext from './hooks/useDentalContext';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import MemberInformationBox from 'src/packages/dental/components/MemberInformationBox';
import { Spinner } from '@digitools/honest-react';
import TabContent from './components/TabContent';
import useMediaQuery from 'src/hooks/useMediaQuery';
import MemberDropdown from 'src/packages/dental/components/MemberDropdown';
import useSetCurrentMember from 'src/packages/dental/hooks/useSetCurrentMember';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { CLAIMS, COVERED_SERVICES, DEDUCTIBLES } from 'src/packages/dental/providers/DentalDataProvider';
const PathNavLink = (props) => {
    return <NavLink {...props}/>;
};
export const StyledNavLink = styled(PathNavLink) `
  color: ${({ theme }) => `${theme.palette.black}`};
  font-weight: 400;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  padding: 1rem 0 1rem 1rem;

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  }

  &:before {
    content: '';
    height: 50%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:focus {
    color: ${({ theme }) => `${theme.palette.primary}`};
    font-weight: 500;
    &:before {
      background: ${({ theme }) => `${theme.palette.primary}`};
    }
  }
`;
export const ErrorHtml = () => {
    const { error } = useDentalDataContext();
    const { t } = useTranslate();
    return error === 'NOT_FOUND' ? (<Alert type={'warning'}>{t(DentalText.deductibleAndCoinsuranceNotFoundWarning)}</Alert>) : (<Alert type={'error'}>{t(DentalText.generalError)}</Alert>);
};
const Dental = () => {
    const { subscriber, loading, activeTab, setActiveTab } = useDentalDataContext();
    const { trackEvent, trackView } = useTealium();
    const { t } = useTranslate();
    const trackDropdownEvent = (member, component) => {
        let page_l5 = TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE;
        if (component === DEDUCTIBLES) {
            page_l5 = TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE;
        }
        if (component === CLAIMS) {
            page_l5 = TEALIUM_EVENT.EVENT_TYPE.DENTAL_CLAIMS;
        }
        if (component === COVERED_SERVICES) {
            page_l5 = TEALIUM_EVENT.EVENT_TYPE.DENTAL_COVERED_SERVICES;
        }
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.CHANGE,
            event_name: TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: member,
        });
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee',
            page_l5: page_l5,
            user_role: 'employee',
        });
    };
    const isMobile = useMediaQuery('(max-width: 1439px)');
    const isTitleView = useMediaQuery('(max-width: 738px)');
    useSetCurrentMember();
    if (loading) {
        return <Spinner data-testid='dental-spinner'/>;
    }
    return (<>
      {(!isTitleView || !activeTab) && (<Row>
          <Col>
            <Heading elemStyle={'h1'}>{t(DentalText.dentalTitle)}</Heading>
          </Col>
        </Row>)}
      {subscriber ? (<>
          {isMobile ? (<>
              {isTitleView && activeTab && activeTab === COVERED_SERVICES && (<Row>
                  <Col>
                    <Heading elemStyle={'h1'}>{t(DentalText.coveredServicesTitle)}</Heading>
                  </Col>
                </Row>)}
              {isTitleView && activeTab && activeTab === DEDUCTIBLES && (<Row>
                  <Col>
                    <Heading elemStyle={'h1'}>{t(DentalText.deductiblesTitle)}</Heading>
                  </Col>
                </Row>)}
              {isTitleView && activeTab && activeTab === CLAIMS && (<Row>
                  <Col>
                    <Heading elemStyle={'h1'}>{t(DentalText.claimsTitle)}</Heading>
                  </Col>
                </Row>)}
              {isTitleView && activeTab && (<Button style={{ padding: '0' }} data-testid={'backButton'} onClick={() => {
                        setActiveTab('');
                    }}>
                  &lt; {t(DentalText.backText)}
                </Button>)}
              {(activeTab === '' || !isTitleView) && (<Row>
                  <Col>
                    <p>{subscriber.planOverview?.planFundingClause}</p>
                  </Col>
                </Row>)}
              <Row>
                <Col>
                  <MemberDropdown trackDropdownEvent={trackDropdownEvent} component={activeTab}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <MemberInformationBox />
                </Col>
              </Row>
              <TabContent />
            </>) : (<Col>
              <Row style={{ padding: '.3rem .3rem .3rem .5rem' }}>{subscriber.planOverview?.planFundingClause}</Row>
              <Row>
                <Col xl={2} lg={3}>
                  <nav style={{ display: 'flex', flexDirection: 'column' }}>
                    {[...[subscriber], ...(subscriber.dependents || [])].map((member, index) => (<StyledNavLink data-testid={`navlink-${member.id}`} key={encode(member?.id)} data-public={true} to={{
                        pathname: `/employee/dental/members/${encode(member?.id)}`,
                    }} onClick={() => {
                        trackDropdownEvent(index === 0 ? 'subscriber' : 'member' + index, activeTab || '');
                    }}>
                        {`${member?.firstName} ${member?.lastName}`}
                      </StyledNavLink>))}
                  </nav>
                </Col>
                <Col xl={10} lg={9}>
                  <div style={{ height: '210px' }}>
                    <MemberInformationBox />
                  </div>
                  <TabContent />
                </Col>
              </Row>
            </Col>)}
        </>) : (<ErrorHtml />)}
    </>);
};
export default Dental;
