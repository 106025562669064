import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { Col, Collapse, Container, Heading, Icon, Row, themes } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
export const AdditionalEligibilityConditions = () => {
    const { currentMember } = useDentalDataContext();
    const { t } = useTranslate();
    const dentalClauses = currentMember?.benefitsAndFrequencies?.dentalClauses;
    const CollapsedIcon = () => <Icon name='add' size='xs' className='mr-2 mt-1' color='primary'/>;
    const ExpandedIcon = () => <Icon name='subtract' size='xs' className='mr-2 mt-1' color='primary'/>;
    return (<Collapse data-testid={'dentalClauseCollapse'} title={t(DentalText.planOverviewConditionsTitle)} collapsedIcon={CollapsedIcon} expandedIcon={ExpandedIcon} headerElemType='h2' theme={themes.lincoln} type='box' isOpen={true} color={'white'}>
      {!!dentalClauses && (<Container>
          <Row>
            <Col>
              {dentalClauses.map(clause => (<>
                  <Heading elemType={'h4'}>{clause.label}</Heading>
                  <p>{clause.value}</p>
                </>))}
            </Col>
          </Row>
        </Container>)}
    </Collapse>);
};
export default AdditionalEligibilityConditions;
