import { Box, Heading, Icon, TabContainer, Table, TableBody, TableData, TableHeaderCol, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { getMemberEvent, getTabs } from 'src/packages/dental/providers/DentalDataProvider';
import React from 'react';
const PlanDeductiblesTableData = (props) => {
    return (<Table className={'mt-0 pt-0 mb-1'}>
      <TableBody>
        {props.plans.map((plan) => plan[props.networkType] && (<TableRow key={plan.coverageType} className={'border-bottom'} isHoverable={false}>
                <TableData className={'border-0'}>
                  <Heading elemType={'h4'}>{plan.coverageType}</Heading>
                </TableData>
                <TableData className={'border-0 text-right font-weight-light'}>
                  {plan[props.networkType].networkDeductible}
                </TableData>
              </TableRow>))}
      </TableBody>
    </Table>);
};
const PlanDeductibles = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const memberEvent = getMemberEvent(currentMember, subscriber);
    const [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, planDeductibles] = getTabs('planDeductibles', currentMember);
    const hasData = !!(planDeductibles && planDeductibles?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const trackDropdownEvent = (networkLabel) => {
        const tab = networkLabel + ' ' + deductiblesAndCoinsurances?.deductibleType + ' deductibles';
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_name: tab,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE,
            event_version: memberEvent,
        });
    };
    return (<Box theme={themes.lincoln} className={'h-100 pb-0'}>
      {!!deductiblesAndCoinsurances && (<>
          <Table className={'pb-0 mb-0'}>
            <TableBody>
              <TableRow className={'border-0'} isHoverable={false}>
                <TableHeaderCol className={'border-0 mr-0 mb-2'}>
                  <Heading data-testid='deductible-type' elemType={'h2'}>
                    {deductiblesAndCoinsurances.deductibleType || ''} {t(DentalText.deductiblesText)}
                    <Icon className={'ml-3 align-middle'} size='xs' name='info' color={'secondary'} title={'deductibles info'}/>
                  </Heading>
                </TableHeaderCol>
              </TableRow>
              <TableRow isHoverable={false}>
                <TableData className={'border-0'}>
                  <Heading elemType={'h4'}>{t(DentalText.deductibleAmountPaidText)}</Heading>
                </TableData>
                <TableData className={'border-0 text-right font-weight-light align-bottom'}>
                  {' '}
                  ${deductiblesAndCoinsurances.amountApplied}
                </TableData>
              </TableRow>
            </TableBody>
          </Table>
          {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
              <TabSection onClick={() => trackDropdownEvent(inNetworkLabel)} label={inNetworkLabel} name={'planDeductiblesinNetworkTab'}>
                <PlanDeductiblesTableData plans={planDeductibles} networkType={'inNetwork'}/>
              </TabSection>
              <TabSection onClick={() => {
                    trackDropdownEvent(outNetworkLabel);
                }} label={outNetworkLabel} name={'planDeductiblesOutNetworkTab'}>
                <PlanDeductiblesTableData plans={planDeductibles} networkType={'outNetwork'}/>
              </TabSection>
            </TabContainer>) : (hasData && (<PlanDeductiblesTableData plans={planDeductibles} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'}/>))}
        </>)}
    </Box>);
};
export default PlanDeductibles;
