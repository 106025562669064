export const EoiGeneralText = {
    header: {
        english: 'Evidence of insurability',
        spanish: 'Evidencia de asegurabilidad',
    },
    nyHeader: {
        english: 'Group Life, Disability, and Critical Illness Evidence of insurability',
        spanish: 'Evidencia de asegurabilidad de vida grupal, discapacidad y enfermedad crítica',
    },
    applicantCoverageStep: {
        english: 'Applicant coverage',
        spanish: 'Cobertura del solicitante',
    },
    applicantInfoStep: {
        english: 'Applicant information',
        spanish: 'Información del aplicante',
    },
    statementOfHealthStep: {
        english: 'Statement of health',
        spanish: 'Declaración de salud',
    },
    qualifyingQuestionsStep: {
        english: 'Qualifying medical questions',
        spanish: 'Preguntas médicas calificativas'
    },
    reflexiveQualifyingQuestionsStep: {
        english: 'Qualifying medical questions continued',
        spanish: 'Preguntas médicas calificativas continuación'
    },
    reflexiveStatementOfHealthStep: {
        english: 'Statement of health continued',
        spanish: 'Continuación de declaración de salud',
    },
    reviewAndSubmitStep: {
        english: 'Review and submit application',
        spanish: 'Revisar y enviar solicitud',
    },
    yes: {
        english: 'Yes',
        spanish: 'Si',
    },
    no: {
        english: 'No',
        spanish: 'No',
    },
    continue: {
        english: 'CONTINUE',
        spanish: 'CONTINUAR',
    },
    saveAndContinue: {
        english: 'SAVE AND CONTINUE',
        spanish: 'GUARDAR Y CONTINUAR',
    },
    back: {
        english: 'BACK',
        spanish: 'ANTERIOR',
    },
    submit: {
        english: 'SUBMIT',
        spanish: 'ENVIAR',
    },
    edit: {
        english: 'Edit',
        spanish: 'Editar',
    },
    requiredError: {
        english: 'This field is required.',
        spanish: 'Este campo es requerido.',
    },
    duplicateSsnError: {
        english: 'Social security number is already in use.',
        spanish: 'El número de seguridad social ya está en uso.',
    },
    dropdownSelectError: {
        english: 'Please select an option.',
        spanish: 'Por favor seleccione una opción.',
    },
    invalideDateError: {
        english: 'Invalid Date',
        spanish: 'Fecha invalida',
    },
    employeeLabel: {
        english: 'Myself',
        spanish: 'Yo mismo',
    },
    dependentLabel: {
        english: 'My dependents or children',
        spanish: 'Mis dependientes o hijos',
    },
    spouseLabel: {
        english: 'My <SPOUSE>',
        spanish: 'Mi <SPOUSE>',
    },
    date: {
        english: 'Date',
        spanish: 'Fecha',
    },
    other: {
        english: 'Other',
        spanish: 'Otro'
    },
    thankYou: {
        english: 'Thank You!',
        spanish: '¡Gracias!',
    },
    validationError: {
        english: 'You must complete the required form fields before moving forward.',
        spanish: 'Debe completar los campos del formulario obligatorios antes de seguir adelante.',
    },
    note: {
        english: 'Note: ',
        spanish: 'Nota: ',
    },
    signatureMatchError: {
        english: 'Signature must match Applicant Name',
        spanish: 'La firma debe coincidir con el nombre del solicitante.',
    },
    confirmEmployer: {
        english: 'Please confirm your employer',
        spanish: 'Por favor confirme a su empleador',
    },
    protectInfo: {
        english: 'To protect your personal information, please make sure the employer we have on file is accurate.',
        spanish: 'Para proteger su información personal, asegúrese de que el empleador que tenemos registrado sea exacto.',
    },
    isMyCurrentEmployer: {
        english: 'is my current employer.',
        spanish: 'es mi empleador actual.',
    },
    notCurrentEmployer: {
        english: 'If this is not your current employer, please call 1-800-431-2958 for assistance, Monday through Thursday 8 AM-8 PM ET and Friday 8 AM - 6 PM ET.',
        spanish: 'Si este no es su empleador actual, llame al 1-800-431-2958 para obtener ayuda, de lunes a jueves de 8 a. m. a 8 p. m., hora del Este, y los viernes de 8 a. m. a 6 p. m., hora del Este.',
    },
};
export const EOIHelpText = {
    helpHeader: {
        english: 'Need Help?',
        spanish: '¿Necesitas ayuda?',
    },
    helpText: {
        english: 'If you have questions related to your coverage, please contact your employer or benefits administrator.',
        spanish: 'Si tiene preguntas relacionadas con su cobertura, comuníquese con su empleador o administrador de beneficios.',
    },
    phoneText: {
        english: 'Phone',
        spanish: 'Teléfono',
    },
    emailText: {
        english: 'Email',
        spanish: 'Email',
    },
    mailText: {
        english: 'Mail',
        spanish: 'Correo',
    }
};
export const EoiEmployeeInfoText = {
    heading: {
        english: 'Employee',
        spanish: 'Employee spanish',
    }
};
