import axios from 'axios';
const NOT_FOUND = 'NOT_FOUND';
const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
const mapError = (errors) => {
    const notFoundError = errors &&
        errors?.find((error) => error.extensions?.classification === NOT_FOUND || error.errorType === NOT_FOUND);
    return notFoundError ? NOT_FOUND : 'INTERNAL_SERVER_ERROR';
};
const dentalGraphqlUrl = '/mlp-dental-consumer-bff/graphql';
export const GetSubscriber = async (authHeader, language) => {
    let subscriberResponse = {};
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query subscriber {
                subscriber {
                    firstName
                    lastName
                    id
                    dateOfBirth
                    effectiveDate
                    terminationDate
                    memberType
                    memberTypeDescription
                    status
                    benefitsAndFrequencies {
                      asOfDate 
                      isFlex
                      preventiveServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      basicServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      majorServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      orthodontia {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      } 
                      dentalClauses {
                        label
                        value
                      }
                    }
                    deductiblesAndCoinsurance {
                        deductibleType
                        amountApplied
                        inNetworkLabel
                        outNetworkLabel
                        calendarMaxUsed
                        maxRewardsAvailable
                        lifetimeOrthoMaxUsed
                        planMaximums {
                            deductibleType
                            planDesc
                            inNetwork {
                                networkMaximum
                            }
                            outNetwork {
                                networkMaximum
                            }
                        }
                        planDeductibles {
                            coverageType
                            inNetwork {
                                networkDeductible
                            }
                            outNetwork {
                                networkDeductible
                            }
                        }
                        coinsurances {
                            serviceCategory
                            categoryDesc
                            covered
                            inNetwork {
                                networkCoinsurance
                                deductibleApplies
                            }
                            outNetwork {
                                networkCoinsurance
                                deductibleApplies
                            }
                        }
                    }
                    planOverview {
                        groupName {
                            value
                            label
                        }
                        groupPolicyNum {
                            value
                            label
                        }
                        coverageType {
                            value
                            label
                        }
                        productType
                        terminationDate {
                            value
                            label
                        }
                        benefitPeriod {
                            value
                            label
                        }
                        coverageSubtype
                        dependentAgeLimit {
                            value
                            label
                        }
                        studentAgeLimit {
                            value
                            label
                        }
                        flexibleIndicator
                        stateRegulated
                        planFundingClause
                    }
                    dependents {
                        firstName
                        lastName
                        id
                        dateOfBirth
                        effectiveDate
                        terminationDate
                        memberType
                        memberTypeDescription
                        status
                }
            }
        }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const subscriber = response?.data?.data?.subscriber;
        const errors = response?.data?.errors;
        subscriberResponse = {
            subscriber: subscriber,
            graphQlError: (errors && mapError(errors)) || (subscriber ? null : INTERNAL_SERVER_ERROR),
        };
        return subscriberResponse;
    })
        .catch(exception => {
        subscriberResponse = { graphQlError: INTERNAL_SERVER_ERROR };
        return subscriberResponse;
    });
};
export const GetDeductibles = async (authHeader, language, memberType, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query deductiblesAndCoinsurance {
                deductiblesAndCoinsurance(memberType: ${memberType}, memberId: "${memberId}") {
                    deductibleType
                    amountApplied
                    inNetworkLabel
                    outNetworkLabel
                     calendarMaxUsed
                     maxRewardsAvailable
                     lifetimeOrthoMaxUsed
                     planMaximums {
                        deductibleType
                        planDesc
                        inNetwork {
                            networkMaximum
                        }
                        outNetwork {
                            networkMaximum
                        }
                     }
                    planDeductibles {
                        coverageType
                        inNetwork {
                            networkDeductible
                        }
                        outNetwork {
                            networkDeductible
                        }
                    }
                    coinsurances {
                        serviceCategory
                        categoryDesc
                        covered
                        inNetwork {
                            networkCoinsurance
                            deductibleApplies
                        }
                        outNetwork {
                            networkCoinsurance
                            deductibleApplies
                        }
                    }
                }
            }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const deductiblesAndCoinsurance = response?.data?.data?.deductiblesAndCoinsurance;
        const errors = response?.data?.errors;
        return {
            deductiblesAndCoinsurance: deductiblesAndCoinsurance,
            graphQlError: errors && mapError(errors),
        };
    });
};
export const GetBenefitsAndFrequencies = async (authHeader, language, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query benefitsAndFrequencies {
                benefitsAndFrequencies( memberId: "${memberId}") {
                      asOfDate 
                      isFlex
                      preventiveServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      basicServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      majorServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      orthodontia {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      } 
                      dentalClauses {
                        label
                        value
                      }
                    }
                    }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const benefitsAndFrequencies = response?.data?.data?.benefitsAndFrequencies;
        const errors = response?.data?.errors;
        return {
            benefitsAndFrequencies: benefitsAndFrequencies,
            graphQlError: errors && mapError(errors),
        };
    });
};
