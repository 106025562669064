import { useEffect } from 'react';
import { decode } from 'status/utilities/hash';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { useParams } from 'react-router-dom';
import { GetBenefitsAndFrequencies, GetDeductibles } from 'src/packages/dental/api/DentalApiCalls';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
const UseSetCurrentMember = () => {
    const { subscriber, setCurrentMember, setInfoLoading, setError } = useDentalDataContext();
    const { memberId } = useParams();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    useEffect(() => {
        if (subscriber && (!memberId || subscriber.id === decode(memberId))) {
            setCurrentMember(subscriber);
        }
        else if (memberId && subscriber?.dependents) {
            const dependent = subscriber.dependents.find((dep) => dep.id === decode(memberId));
            if (dependent) {
                //check we got a match
                setCurrentMember(dependent);
                setInfoLoading(true);
                (async () => {
                    const benefitsResponse = await GetBenefitsAndFrequencies(authHeader(), language, dependent.id);
                    const deductiblesResponse = await GetDeductibles(authHeader(), language, dependent.memberType, dependent.id);
                    dependent.benefitsAndFrequencies = benefitsResponse?.benefitsAndFrequencies;
                    dependent.deductiblesAndCoinsurance = deductiblesResponse?.deductiblesAndCoinsurance;
                    const err = benefitsResponse?.graphQlError || deductiblesResponse.graphQlError || '';
                    if (err) {
                        setError(err);
                    }
                })().finally(() => setInfoLoading(false));
            }
        }
    }, [language, subscriber, memberId]);
};
export default UseSetCurrentMember;
