const today = new Date();
const disclaimerDate = `${new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
}).format(today)}`;
export const DentalText = {
    dentalTitle: {
        english: 'Dental insurance',
        spanish: 'Seguro dental',
    },
    claimsTitle: {
        english: 'Claims',
        spanish: 'Reclamos',
    },
    coveredServicesTitle: {
        english: 'Covered services',
        spanish: 'Servicios cubiertos',
    },
    deductiblesTitle: {
        english: 'Deductibles and coinsurance',
        spanish: 'Deducibles y coseguro',
    },
    dropdownText: {
        english: 'Select a member',
        spanish: 'Seleccione un miembro',
    },
    effectiveDateText: {
        english: 'Effective date',
        spanish: 'Fecha de entrada en vigor',
    },
    terminationDateText: {
        english: 'Termination date',
        spanish: 'Fecha del plazo',
    },
    dateOfBirthText: {
        english: 'Date of birth',
        spanish: 'Fecha de nacimiento',
    },
    coveredServicesText: {
        english: 'Covered services',
        spanish: 'Servicios cubiertos',
    },
    deductibleText: {
        english: 'Deductibles and coinsurance',
        spanish: 'Deducibles y coaseguro',
    },
    claimsText: {
        english: 'Claims',
        spanish: 'Reclamaciones',
    },
    backText: {
        english: 'Back',
        spanish: 'Atrás',
    },
    disclaimerText: {
        english: `The summary provided below is based on processed claims as of ${disclaimerDate}. Future benefits will be applied in the order that claims are received and processed.`,
        spanish: `El resumen que se proporciona a continuación se basa en las reclamaciones procesadas a partir del ${disclaimerDate}. Los beneficios futuros se aplicarán en el orden en que se reciban y procesen las reclamaciones.`,
    },
    deductibleAmountPaidText: {
        english: 'Amount paid towards deductible',
        spanish: 'Monto pagado para el deducible',
    },
    deductiblesText: {
        english: 'deductibles',
        spanish: 'deducibles',
    },
    coinsuranceText: {
        english: 'coinsurance',
        spanish: 'coaseguro',
    },
    coinsuranceTitleText: {
        english: 'Coinsurance',
        spanish: 'Coaseguro',
    },
    youPayText: {
        english: 'You pay',
        spanish: 'Tú pagas',
    },
    deductibleAppliesText: {
        english: 'Deductible applies',
        spanish: 'Se aplica deducible',
    },
    planMaximumsText: {
        english: 'Plan maximums',
        spanish: 'Máximos del Plan',
    },
    individualAnnualText: {
        english: 'Individual annual used to date',
        spanish: 'Anual individual utilizada hasta la fecha',
    },
    indvidualLifetimeText: {
        english: 'Individual lifetime ortho used to date',
        spanish: 'Ortopedia individual de por vida utilizada hasta la fecha',
    },
    maxRewardsText: {
        english: 'Max rewards available',
        spanish: 'Recompensas máximas disponibles',
    },
    planMaximumsAnnualText: {
        english: 'For Preventative, Basic, & Major Services Combined',
        spanish: 'Para Servicios Preventivos, Básicos y Mayores Combinados',
    },
    deductibleAndCoinsuranceNotFoundWarning: {
        english: `We're sorry for the inconvenience, but we are unable to provide deductible and coinsurance information for this plan. Please contact us for further assistance.`,
        spanish: `Lamentamos el inconveniente, pero no podemos proporcionar información sobre el deducible y el coseguro para esto plan. Por favor contáctenos para obtener más ayuda.`,
    },
    generalError: {
        english: 'Error: We are unable to retrieve this information at this time. Please try again later.',
        spanish: 'Error: No podemos recuperar esta información en este momento. Inténtelo de nuevo más tarde.',
    },
    planOverviewConditionsTitle: {
        english: 'Additional eligibility conditions',
        spanish: 'Condiciones de elegibilidad adicionales',
    },
};
export default DentalText;
