import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { Col, Spinner } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
import PlanOverview from 'src/packages/dental/components/PlanOverview';
import AdditionalEligibilityConditions from 'src/packages/dental/components/AdditionalEligibilityConditions';
//TODO This is where the content will go in the subsequent stories, this is a placeholder
export const CoveredServicesInfo = () => {
    const { infoLoading } = useDentalDataContext();
    if (infoLoading) {
        return <Spinner data-testid='coveredServices-spinner'/>;
    }
    return (<>
      <PlanOverview />
      <AdditionalEligibilityConditions />
    </>);
};
const CoveredServices = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<Col className={'mt-0 p-0'}>
          <p>{t(DentalText.disclaimerText)}</p>
          <PlanOverview />
          <AdditionalEligibilityConditions />
        </Col>)}
    </>);
};
export default CoveredServices;
