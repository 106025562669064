import { Box, Heading, SelectInput, themes } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import { encode } from 'status/utilities/hash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
const MemberDropdown = (props) => {
    const navigate = useNavigate();
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<Box className={'pb-0'} color={'primary'} data-testid='dental-dropdown-box'>
          <Heading color={'white'} elemType={'h2'}>
            {t(DentalText.dropdownText)}
          </Heading>
          <SelectInput hidePlaceholder={true} style={{ borderRadius: '4px' }} ariaLabelText={'Member'} data-testid='member-dropdown' field={'member'} title={'member-dropdown'} initialValue={`${currentMember.firstName} ${currentMember.lastName}`} onFocus={() => { }} options={[
                ...[
                    {
                        label: `${subscriber.firstName} ${subscriber.lastName}`,
                        value: subscriber.id,
                    },
                ],
                ...(subscriber.dependents?.map(dependent => ({
                    label: `${dependent.firstName} ${dependent.lastName}`,
                    value: dependent.id,
                })) || []),
            ]} onChange={(event) => {
                navigate(`/employee/dental/members/${encode(event.target.value)}`);
                if (event.target.selectedIndex - 1 === 0) {
                    props.trackDropdownEvent('subscriber', props.component);
                }
                else {
                    props.trackDropdownEvent('member' + (event.target.selectedIndex - 1), props.component);
                }
            }} required={true} theme={themes.lincoln}/>
        </Box>)}
    </>);
};
export default MemberDropdown;
