import { Box, Heading, Icon, TabContainer, Table, TableBody, TableData, TableHeaderCol, TableRow, TabSection, themes, } from '@digitools/honest-react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { getMemberEvent, getTabs } from 'src/packages/dental/providers/DentalDataProvider';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
const CoinsuranceRows = (props) => {
    const { t } = useTranslate();
    return (<Table className={'pt-0 mt-0 mb-1'}>
      <TableBody>
        <TableRow className={'border-bottom'} isHoverable={false}>
          <TableData className={'border-0'}></TableData>
          <TableData className={'border-0'}>
            <Heading elemType={'h4'}>{t(DentalText.youPayText)}</Heading>
          </TableData>
          <TableData className={'border-0'}>
            <Heading elemType={'h4'}>{t(DentalText.deductibleAppliesText)}</Heading>
          </TableData>
        </TableRow>
        {props.coinsurances.map(coinsurance => coinsurance[props.networkType] && (<TableRow key={coinsurance.serviceCategory} className={'border-bottom'} isHoverable={false}>
                <TableData className={'border-0'}>
                  <Heading elemType={'h4'}>{coinsurance.serviceCategory}</Heading>
                </TableData>
                <TableData className={'border-0 font-weight-light'}>
                  {coinsurance[props.networkType].networkCoinsurance}
                </TableData>
                <TableData className={'border-0 font-weight-light'}>
                  {coinsurance[props.networkType].deductibleApplies}
                </TableData>
              </TableRow>))}
      </TableBody>
    </Table>);
};
const Coinsurances = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const memberEvent = getMemberEvent(currentMember, subscriber);
    const [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, coinsurances] = getTabs('coinsurances', currentMember);
    const hasData = !!(coinsurances && coinsurances?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const trackDropdownEvent = (networkType) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_name: `${networkType} ${t(DentalText.coinsuranceText)}`,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE,
            event_version: memberEvent,
        });
    };
    return (<Box theme={themes.lincoln} className={'pb-0'}>
      <Table className={'pb-0 mb-0'}>
        <TableRow className={'border-0'} isHoverable={false}>
          <TableHeaderCol className={'border-0 mr-0 mb-2'}>
            <Heading data-testid='coinsurance' elemType={'h2'}>
              {t(DentalText.coinsuranceTitleText)}
              <Icon className={'ml-3 align-middle'} size='xs' name='info' color={'secondary'} title={'coinsurance info'}/>
            </Heading>
          </TableHeaderCol>
        </TableRow>
      </Table>
      {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
          <TabSection onClick={() => {
                trackDropdownEvent(inNetworkLabel);
            }} label={inNetworkLabel} name={'coinsurancesInNetworkTab'}>
            <CoinsuranceRows coinsurances={coinsurances} networkType={'inNetwork'}/>
          </TabSection>
          <TabSection onClick={() => {
                trackDropdownEvent(outNetworkLabel);
            }} label={outNetworkLabel} name={'coinsurancesOutNetworkTab'}>
            <CoinsuranceRows coinsurances={coinsurances} networkType={'outNetwork'}/>
          </TabSection>
        </TabContainer>) : (hasData && (<CoinsuranceRows coinsurances={coinsurances} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'}/>))}
    </Box>);
};
export default Coinsurances;
