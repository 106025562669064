import { Box, Heading, Icon, TabContainer, Table, TableBody, TableData, TableHeaderCol, TableRow, TabSection, themes, } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import DentalText from 'src/packages/dental/constants/DentalText';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { getMemberEvent, getTabs } from 'src/packages/dental/providers/DentalDataProvider';
const PlanMaximumsTableData = (props) => {
    return (<Table className={'pt-0 mt-0 mb-1'}>
      <TableBody>
        {props.planMaximums.map(plan => plan[props.networkType] && (<TableRow key={plan.deductibleType} className={'border-bottom'} isHoverable={false}>
                <TableData className={'border-0'}>
                  <Heading elemType={'h4'}>{plan.deductibleType}</Heading>
                  <p className={'font-weight-light font-italic'}>{plan.planDesc}</p>
                </TableData>
                <TableData className={'border-0 text-right font-weight-light'}>
                  ${plan[props.networkType].networkMaximum}
                </TableData>
              </TableRow>))}
      </TableBody>
    </Table>);
};
const PlanMaximums = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const [inNetworkLabel, outNetworkLabel, deductibles, planMaximums] = getTabs('planMaximums', currentMember);
    const hasData = !!(planMaximums && planMaximums?.length);
    const hasMultiTabs = !!(inNetworkLabel.length && outNetworkLabel.length);
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const memberEvent = getMemberEvent(currentMember, subscriber);
    const trackDropdownEvent = (networkLabel) => {
        const tab = `${networkLabel} plan maximums`;
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_name: tab || '',
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_DEDUCTIBLES_AND_COINSURANCE,
            event_version: memberEvent || '',
        });
    };
    return (<Box theme={themes.lincoln} className={'h-100 pb-0'}>
      <Table className={'pb-0 mb-0'}>
        <TableBody>
          <TableRow className={'border-0'} isHoverable={false}>
            <TableHeaderCol className={'border-0 mr-0 mb-4'}>
              <Heading elemStyle={'h2'}>
                {t(DentalText.planMaximumsText)}
                <Icon className={'ml-3 align-middle'} size='xs' name='info' color={'secondary'} title={'deductibles info'}/>
              </Heading>
            </TableHeaderCol>
          </TableRow>
          {!!deductibles && (<>
              <TableRow isHoverable={false}>
                <TableData className={'border-0'}>
                  <Heading elemType={'h4'}>{t(DentalText.individualAnnualText)}</Heading>
                </TableData>
                <TableData className={'border-0 text-right font-weight-light align-bottom'}>
                  ${deductibles?.calendarMaxUsed}
                </TableData>
              </TableRow>
              {deductibles.lifetimeOrthoMaxUsed != null && (<TableRow className={'border-top'}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.indvidualLifetimeText)}</Heading>
                  </TableData>
                  <TableData className={'border-0'}>${deductibles?.lifetimeOrthoMaxUsed}</TableData>
                </TableRow>)}
              {deductibles.maxRewardsAvailable != null && (<TableRow className={'border-top'}>
                  <TableData className={'border-0'}>
                    <Heading elemType={'h4'}>{t(DentalText.maxRewardsText)}</Heading>
                  </TableData>
                  <TableData className={'border-0'}>${deductibles?.maxRewardsAvailable}</TableData>
                </TableRow>)}
            </>)}
        </TableBody>
      </Table>
      {hasData && hasMultiTabs ? (<TabContainer noVertical={true}>
          <TabSection onClick={() => {
                trackDropdownEvent(inNetworkLabel);
            }} label={inNetworkLabel} name={'planMaximumsInNetworkTab'}>
            <PlanMaximumsTableData planMaximums={planMaximums} networkType={'inNetwork'}/>
          </TabSection>
          <TabSection onClick={() => {
                trackDropdownEvent(outNetworkLabel);
            }} label={outNetworkLabel} name={'planMaximumsOutNetworkTab'}>
            <PlanMaximumsTableData planMaximums={planMaximums} networkType={'outNetwork'}/>
          </TabSection>
        </TabContainer>) : (hasData && (<PlanMaximumsTableData planMaximums={planMaximums} networkType={inNetworkLabel ? 'inNetwork' : 'outNetwork'}/>))}
    </Box>);
};
export default PlanMaximums;
