import { Alert, Col, Heading, Loader, Modal, Row, TextInput, themes } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatName, isNotEmpty } from 'src/utils/validationUtils';
import { useGetLandingPageResource } from '../../../api/LandingPageApi';
import Notifications, { FunctionalArea } from '../../../components/Notifications/Notifications';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useCustomerConfig from '../../../hooks/useCustomerConfig';
import useExperience from '../../../hooks/useExperience';
import useTealium from '../../../hooks/useTealium';
import useTranslate from '../../../hooks/useTranslate';
import { Experience } from '../../../types/Experience';
import { Language } from '../../../types/Language';
import { getEnvVariable } from '../../../utils/envUtils';
import CustomerBar from '../components/CustomerBar/CustomerBar';
import ContentBlocks from '../components/landingPageContent/ContentBlocks';
import LandingPageBanner from '../components/landingPageContent/LandingPageBanner';
import { getContentForModal, renderModalFooter, renderModalFooterCancelButton } from './TermsAndConditionsModalContent';
import LogRocket from 'logrocket';
import { withRouter } from 'src/utils/withRouter';
import ProvePrompt from 'src/components/prove/ProvePrompt';
const acceptLanguage = {
    english: 'By clicking accept, you agree to the below:',
    spanish: 'Al hacer clic en aceptar, usted acepta lo siguiente',
};
const termsConditions = {
    english: 'Privacy Statement and Web Agreement',
    spanish: 'Declaración de privacidad y el Acuerdo web'
};
const NewHomePageContainer = () => {
    const { language, experience, changeLanguage } = useExperience();
    const { authHeader, user, logout } = useAuthentication();
    const { navigationMenu } = useCustomerConfig();
    const { trackView, trackEvent } = useTealium();
    const { t } = useTranslate();
    const location = useLocation();
    const [landingPageResource, setLandingPageResource] = useState([]);
    const formApiRef = useRef();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const fullContext = { user, authHeader, language, changeLanguage, formApiRef, navigate, setLoading, loading, trackEvent, logout };
    const setFormApi = (formApi) => {
        formApiRef.current = formApi;
    };
    const { error: landingPageResourceError, loading: loadingLandingPageResource, data: landingPageResourceData, get: triggerGetLandingPageResource, } = Object(useGetLandingPageResource(experience));
    useLayoutEffect(() => {
        document.title = `My Lincoln Portal - ${experience} Home`;
        if (user?.isClientServiceRep) {
            window.location.assign(getEnvVariable('cliUrlCsr'));
        }
    });
    useEffect(() => {
        setLandingPageResource(landingPageResourceData || []);
    }, [landingPageResourceData]);
    useEffect(() => {
        const logRocketAppId = getEnvVariable('LOGROCKET_APP_ID');
        if (logRocketAppId && user && user.customerInContext) {
            LogRocket.identify(user.loginId, {
                isLFGUser: user.isLfgUser,
                isSmallMid: user.customerInContext.smallMid,
                isSSO: user.ssoUser,
            });
        }
    }, []);
    useEffect(() => {
        if (experience !== Experience.PUBLIC) {
            triggerGetLandingPageResource();
        }
    }, [experience]);
    useEffect(() => {
        if (!loadingLandingPageResource && landingPageResourceData) {
            trackView({
                page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.HOME_PAGE,
                page_l4: experience === Experience.EMPLOYER
                    ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER
                    : TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE,
                page_plan_code: user?.customerId,
            });
        }
    }, [loadingLandingPageResource, landingPageResourceData]);
    const hasApplications = navigationMenu &&
        navigationMenu.some(navGroup => {
            return navGroup.id.includes('REQUESTS_AND_STATUS') || navGroup.id.includes('REPORTING');
        });
    return location?.state?.action === 'needsToAcceptTermsAndConditions' && !user?.isLfgUser ? (
    // TODO: Clean this modal up
    <FocusLock>
      <Form getApi={setFormApi}>
          <Modal data-public={true} autoOpen={true} disabledBackdropClick={true} hasCloseIcon={false} headerComponent={language === Language.ENGLISH ? (<Heading elemType={'h2'} color={'primary'} theme={themes.secureSite}>
                  To continue, complete the following for our records:
                </Heading>) : (<Heading elemType={'h2'} color={'primary'} theme={themes.secureSite}>
                  Para continuar, complete lo siguiente para nuestros registros:
                </Heading>)} footerPrimaryRenderButton={() => renderModalFooter(fullContext)} footerSecondaryRenderButton={() => renderModalFooterCancelButton(fullContext)} footerContentPosition={'flex-start'}>
            <Loader isShown={loading} size={'small'}>
            {!user?.termsAndConditionsAccepted && !user?.isLfgUser &&
            <div data-testid={'termsAndConditionsModal'} role={'alertdialog'}>
              {getContentForModal(fullContext)}
            </div>}
          
            {(user?.firstName?.toLowerCase() === 'EMPTY_FIRST_NAME'.toLowerCase() ||
            user?.lastName?.toLowerCase() === 'EMPTY_LAST_NAME'.toLowerCase() ||
            user?.firstName === undefined ||
            user?.lastName === undefined) && (<>
                <div style={{ overflowX: 'hidden' }}>
                  
                  <Row>
                    <Col className={'mt-3 col-sm-12 col-md-12'}>
                      <strong>
                        {language === Language.ENGLISH
                ? 'Provide your First and Last name'
                : 'Proporcione su nombre y apellido'}
                      </strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={'mt-1 col-sm-12 col-md-6'}>
                      <TextInput data-private={true} id={'firstName'} field={'firstName'} initialValue='' label={language === Language.ENGLISH ? 'First name' : 'Nombre'} mask={formatName} maxLength={35} type={'text'} placeholder={language === Language.ENGLISH ? 'Enter first name' : 'Ingresar nombre'} validate={isNotEmpty} validateOnChange={true} validateOnBlur={true}/>
                    </Col>
                    <Col className={'mt-1 col-sm-12 col-md-6'}>
                      <TextInput data-private={true} id={'lastName'} field={'lastName'} initialValue='' label={language === Language.ENGLISH ? 'Last name' : 'Apellido'} mask={formatName} maxLength={35} type={'text'} placeholder={language === Language.ENGLISH ? 'Enter last name' : 'Ingresar apellido'} validate={isNotEmpty} validateOnChange={true} validateOnBlur={true}/>
                    </Col>
                  </Row>
                </div>
              </>)}
            <div className={'mt-2'}>
              <strong>{t(acceptLanguage)}</strong>
              {!user?.termsAndConditionsAccepted && !user?.isLfgUser &&
            <div data-testid="termsConditionsListed">
                <strong>{t(termsConditions)}</strong>
                </div>}
              {!user?.elecConsentAcceptedDate && !user?.isLfgUser &&
            <div data-testid='eConsentListed'>
                  <div>
                    <a href="https://www.lincolnfinancial.com/public/general/legal/consentforelectronictransmissions" target="_blank"><b>eTransmissions</b></a>
                  </div>
                  <div>
                    <a href="https://www.lincolnfinancial.com/public/general/legal/consentforelectronicsignature" target="_blank"><b>eSignatures</b></a>
                  </div>
                </div>}
              
            </div>
            </Loader>
          </Modal>
      </Form>
    </FocusLock>) : (<Row data-public={true}>
      <Col md={`12`}>
        <Loader viewportCentered={true} id='home-page-spinner' isShown={loadingLandingPageResource} data-testid={'loading-indicator'}>
          {experience === Experience.EMPLOYER && navigationMenu && !hasApplications && (<Alert id='erSetUpAlert' type={'error'}>
              Please contact your administrator to get set up with proper system access.
            </Alert>)}
          <div className={'mb-3'}>
          <Notifications functionalArea={experience === Experience.EMPLOYER ? FunctionalArea.EMPLOYER_LANDING : FunctionalArea.EMPLOYEE_LANDING}/>
          </div>
          <ProvePrompt />
          <CustomerBar landingPageResource={landingPageResource}/>
          <LandingPageBanner />
          <ContentBlocks />
        </Loader>
      </Col>
    </Row>);
};
export default withRouter(NewHomePageContainer);
