import useAuthentication from 'src/hooks/useAuthentication';
import React, { createContext, useEffect, useState } from 'react';
import { GetSubscriber } from '../api/DentalApiCalls';
import useExperience from 'src/hooks/useExperience';
export var MemberType;
(function (MemberType) {
    MemberType["SUBSCRIBER"] = "SUBSCRIBER";
    MemberType["SPOUSEorPARTNER"] = "SPOUSE or PARTNER";
    MemberType["DEPENDENTCHILD"] = "DEPENDENT CHILD";
})(MemberType || (MemberType = {}));
export var CoverageSubType;
(function (CoverageSubType) {
    CoverageSubType["SELF_FUNDED"] = "SELF_FUNDED";
    CoverageSubType["FULLY_INSURED"] = "FULLY_INSURED";
    CoverageSubType["VOLUNTARY"] = "VOLUNTARY";
})(CoverageSubType || (CoverageSubType = {}));
export const COVERED_SERVICES = 'coveredServices';
export const DEDUCTIBLES = 'deductibles';
export const CLAIMS = 'claims';
export const sentenceCase = (s) => (s ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}` : s);
export const getTabs = (deductibleType, currentMember) => {
    const deductiblesAndCoinsurances = currentMember?.deductiblesAndCoinsurance;
    const inNetworkLabel = deductiblesAndCoinsurances?.inNetworkLabel || '';
    const outNetworkLabel = deductiblesAndCoinsurances?.outNetworkLabel || '';
    const data = deductiblesAndCoinsurances && deductiblesAndCoinsurances[deductibleType];
    return [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, data];
};
export const getMemberEvent = (currentMember, subscriber) => {
    const currentMemberIndex = (currentMember &&
        subscriber?.dependents &&
        subscriber.dependents.length > 0 &&
        subscriber.dependents.indexOf(currentMember)) ||
        -1;
    return currentMemberIndex === -1 ? 'subscriber' : 'member' + (currentMemberIndex + 1);
};
export const DentalDataContext = createContext({
    subscriber: undefined,
    setSubscriber: () => { },
    currentMember: undefined,
    setCurrentMember: () => { },
    error: undefined,
    setError: () => { },
    loading: false,
    setLoading: () => { },
    infoLoading: false,
    setInfoLoading: () => { },
    activeTab: undefined,
    setActiveTab: () => { },
});
export const DentalDataProvider = ({ children, }) => {
    const { authHeader } = useAuthentication();
    const [loading, setLoading] = useState(false);
    const [infoLoading, setInfoLoading] = useState(false);
    const [subscriber, setSubscriber] = useState();
    const [currentMember, setCurrentMember] = useState();
    const [error, setError] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const { language } = useExperience();
    useEffect(() => {
        setLoading(true);
        GetSubscriber(authHeader(), language)
            .catch(error => {
            setError(error.message);
        })
            .then(subscriberResponse => {
            const subscriber = subscriberResponse?.subscriber;
            setSubscriber(subscriber);
            setCurrentMember(subscriber);
            setError(subscriberResponse?.graphQlError || '');
        })
            .finally(() => {
            setLoading(false);
        });
    }, [language]);
    const dentalDataContext = {
        subscriber,
        setSubscriber,
        currentMember,
        setCurrentMember,
        error,
        setError,
        loading,
        setLoading,
        infoLoading,
        setInfoLoading,
        activeTab,
        setActiveTab,
    };
    return <DentalDataContext.Provider value={dentalDataContext}>{children}</DentalDataContext.Provider>;
};
