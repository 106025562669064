import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React from 'react';
import { Col, Container, Row, Spinner } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import PlanDeductibles from 'src/packages/dental/components/PlanDeductibles';
import Coinsurances from 'src/packages/dental/components/Coinsurances';
import PlanMaximums from 'src/packages/dental/components/PlanMaximums';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { ErrorHtml } from 'src/packages/dental/Dental';
export const DeductibleAndCoinsuranceInfo = () => {
    const { infoLoading, subscriber } = useDentalDataContext();
    const isMobile = useMediaQuery('(max-width: 768px)');
    if (infoLoading) {
        return <Spinner data-testid='deductible-spinner'/>;
    }
    const data = subscriber?.deductiblesAndCoinsurance;
    const hasCoinsurance = !!data?.coinsurances?.length;
    const hasPlanMaximum = !!data?.planMaximums?.length;
    const hasPlanDeductibles = !!data?.planDeductibles?.length;
    const hasData = hasCoinsurance || hasPlanMaximum || hasPlanDeductibles;
    return (<>
      {hasData ? (<Container className={'w-100'} fluid={true}>
          {isMobile ? (<>
              <Row>
                <Col>
                  <PlanDeductibles />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PlanMaximums />
                </Col>
              </Row>
            </>) : (<Row>
              <Col>
                <PlanDeductibles />
              </Col>
              <Col>
                <PlanMaximums />
              </Col>
            </Row>)}
          <Row>
            <Col>
              <Coinsurances />
            </Col>
          </Row>
        </Container>) : (<ErrorHtml />)}
    </>);
};
const DeductibleAndCoinsurance = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<>
          <p>{t(DentalText.disclaimerText)}</p>
          <DeductibleAndCoinsuranceInfo />
        </>)}
    </>);
};
export default DeductibleAndCoinsurance;
